import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    modules: $setup.modules,
    "slides-per-view": 1,
    "space-between": 16,
    loop: true,
    centeredSlides: true,
    autoplay: {
      "delay": 2500,
      "disableOnInteraction": false
    },
    breakpoints: {
      "640": {
        "slidesPerView": 1.5,
        "spaceBetween": 16
      },
      "1024": {
        "slidesPerView": 2.5,
        "spaceBetween": 16
      },
    }
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.blok.columns, (blok) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: blok._uid,
          class: "flex-auto"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(blok.component), { blok: blok }, null, 8, ["blok"]))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["modules", "breakpoints"]))
}