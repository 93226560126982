
import Feature from './Feature.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, {
  Autoplay
} from 'swiper'
// import Swiper and modules styles
import 'swiper/swiper.min.css'

SwiperCore.use([Autoplay])

export default {
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  components: {
    Feature,
    Swiper,
    SwiperSlide
  },
  setup () {
    return {
      modules: [Autoplay]
    }
  }
}
