import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "teaser container mt-4 mb-5" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "teaser__inner col-lg-8 col-xl-6" }
const _hoisted_4 = { class: "teaser__inner-title text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_editable = _resolveDirective("editable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString($props.blok.headline), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.blok.text.content, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item,
            class: "teaser__inner-content"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (blok) => {
              return (_openBlock(), _createElementBlock("div", {
                key: blok,
                class: "mb-4"
              }, _toDisplayString(blok.text), 1))
            }), 128))
          ]))
        }), 128))
      ], 512), [
        [_directive_editable, $props.blok]
      ])
    ])
  ]))
}