import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feature py-2" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_editable = _resolveDirective("editable")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "mx-auto object-cover",
      src: $props.blok.image.filename
    }, null, 8, _hoisted_2)
  ], 512)), [
    [_directive_editable, $props.blok]
  ])
}